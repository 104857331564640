@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  @font-face {
    font-family: Montserrat;
    src: url("./fonts/montserrat/Montserrat-VariableFont_wght.ttf") format("truetype");
  }

}


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box
}


body {
  font-family: "TTI"
}

::-webkit-scrollbar {display:none;}

input:focus {
  border: "1px solid red" !important;
}


.carousel-container {
  margin: 50px 0 40px;
}
.carousel {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* height: 60vh; */
}

.carousel-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px;
}
.carousel-header .underline {
  height: 10px;
  border-radius: 6px;
  width: 170px;
  margin-bottom: 24px;
  background: var(--color-secondary);
}
.carousel-header > h3 {
  font-size: 1.8rem;
  margin-bottom: 2px;
  font-family: "Edu NSW ACT Foundation", cursive;
}

.carousel .control-dots {
  
  text-align: left !important;
  padding-left:9% !important;
  padding-right: 30px !important
}
.carousel .control-dots .dot {
  box-shadow: none !important;
  background-color: #E3E1E1 !important;
  opacity: 1 !important;
}

.carousel .control-dots .dot.selected {
  opacity: 1 !important;
  background-color: #395AB5 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #395AB5 !important;
}


select option {
  background-color: white !important;
  color: black !important;
}

select option:hover {
  background-color: lightgray !important;
}

.form-radio {
  background-color: #fff !important;
}

.data-grid-column-headers {
  background-color: #f0f0f0 !important;
}

.css-eacsrj-MuiDataGrid-root .MuiDataGrid-container--top {
  background-color: #f0f0f0 !important
}

.css-eacsrj-MuiDataGrid-root .MuiDataGrid-container--top [role=row],
.css-eacsrj-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row] {
  background-color: #f0f0f0 !important;
  height: "10px"
}


.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0 !important;
  height: 33px !important;
}

.form-radio {
  appearance: none;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  display: inline-block;
  position: relative;
}

.form-radio:checked::before {
  content: '';
  display: block;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: #000000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}